$().ready(function(){
    setupSlideMenu();
     if ($('form#congrats').length){
        $('form').each(function(){
            $(this).addClass('floatlabel-active').validate({
                ignore: ':hidden:not(.file-upload-tool)',
                rules: {
                    "Marriage Certificate": {
                        require_from_group: [1, '.file-upload-tool']
                    },
                    "Child Birth Certificate": {
                        require_from_group: [1, '.file-upload-tool']
                    },
                    "Statutory Declaration": {
                        require_from_group: [1, '.file-upload-tool']
                    },
                    "Birth Certificate": {
                        require_from_group: [1, '.file-upload-tool']
                    }
                }
            });
            $(this).find('.js-float-wrap').FloatLabel();
        });
    }
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
    if ($(".banners .banner").length > 1){
        scrollBanners();
    }
    stickyNav();
    $('.alert button.close span').click(function(){
        stickyNav();
    });
});

function stickyNav() {
    var alert = $('#alerts');
    var mobileMenu = $('#mobile-menu');
    var header = $('header.top');
    if (alert.outerHeight() > 0) {
        var stickyPos = $('#alerts').outerHeight();
        var mobileMenuPos = stickyPos + header.outerHeight() + 50;
        mobileMenu.css('top', mobileMenuPos);
    } else {
        var stickyPos = header.outerHeight();
        var mobileMenuPos = '90px';
    }

    $(document).scroll(function(){
        if (alert.outerHeight() > 0) {
            if ($(window).scrollTop() >= stickyPos){
                $('header').addClass('sticky');
                mobileMenu.addClass('sticky');
                mobileMenu.css('top', '90px');
            } else if ($(window).scrollTop() < stickyPos){
                $('header').removeClass('sticky');
                mobileMenu.removeClass('sticky');
                mobileMenu.css('top', mobileMenuPos);
            }
        }
    });
}



function twoColumn() {
    $('.page-content').addClass('two-col');
    $('button.full-width').removeClass('clicked');
    $('button.two-col').addClass('clicked');
}

function fullWidth() {
    $('.page-content').removeClass('two-col');
    $('button.two-col').removeClass('clicked');
    $('button.full-width').addClass('clicked');
}
var buttons = document.getElementById("page-view");
if (buttons) {
    document.querySelector('#page-view .full-width').addEventListener('click', fullWidth);
    document.querySelector('#page-view .two-col').addEventListener('click', twoColumn);
}

var rotInt;
function scrollBanners(){
    $(".banners-main").each(function(index, element){
        if ($(this).find(".banner").length > 1){
            var $scroller = $(this).find(".banner-stage");

            function slide(offset){
                var $distance = $scroller.innerWidth();
                var $before = $scroller.find(".banner.active").prev();
                var $after = $scroller.find(".banner.active").next();

                if (!$scroller.data('animating')){
                    $scroller.data('animating', true);
                    if (offset < 0) { //fwd next
                        if ($after.length>0){
                            var newPos = $scroller.scrollLeft() + $distance;
                            $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                                $scroller.data('animating', false);
                            });
                            // set active
                            $scroller.find('.banner.active').removeClass('active');
                            $after.addClass('active');
                            var i= $after.index();
                        } else { /* you're at the end - go to first */
                            var startPos = $scroller.scrollLeft(0);
                            $scroller.stop().animate({scrollLeft:startPos},500,'swing', function(){
                                $scroller.data('animating', false);
                            });
                            // set active
                            $scroller.find('.banner.active').removeClass('active');
                            $scroller.find(".banner").first().addClass("active");
                        }
                    } else { //button index
                        var $index = offset;
                        var $new = $scroller.find(".banner").eq($index);
                        var newPos = $scroller.scrollLeft() + $new.position().left;
                        $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                            $scroller.data('animating', false);
                        });
                        clearInterval(rotInt);
                        /* set active */
                        $scroller.find('.banner.active').removeClass('active');
                        $new.addClass('active');
                        rotInt = setInterval(function(){slide(-1);}, 5000);
                    }
                }
            }
            /* auto rotate */
            rotInt = setInterval(function(){slide(-1);}, 5000);
        }
    });
}

function setupSlideMenu(){

    $('#mobile-menu, .slide-menu').click(function(){
        $("body").toggleClass('slide-menu-active');
    });

    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });

    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}